<script>
import { MyComponent } from "@/components/MyComponent.js";
import moment from "moment";

export default {
  name: "Space",
  mixins: [MyComponent],
  data: function () {
    return {
      SpaceID: null,
    };
  },
  computed: {
    isDueSoon() {
      if (this.space) {
        const dnp = this.space.space_company.date_next_payment;
        const days = moment().add(7, "days");
        return moment(dnp).isBefore(days);
      }
      return false;
    },
    dateNextPayment() {
      if (this.space) {
        const dnp = this.space.space_company.date_next_payment;
        if (dnp) {
          return moment(dnp).format("dddd, MMMM Do YYYY, h:mm:ss a");
        }
      }
      return null;
    },
    isAdmin() {
      return this.$store.getters["Manager/isAdmin"](this.slug);
    },
    slug() {
      return this.$route.params.slug;
    },
    space() {
      return this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
  },
};
</script>

<template>
  <div id="Space" class="row" v-if="space">
    <div class="col">
      <div class="row justify-content-center">
        <div class="col-md-6 col-10 text-center offset-md-2" id="space_name">
          <div class="row justify-content-center">
            <div class="name col-12">
              <h3 v-if="space">Space: {{ space.name }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div id="left-nav" class="col-12 col-md-2">
          <div class="col-md-12">
            <div
              id="dateNextPayment"
              v-if="dateNextPayment"
              :class="{
                'bg-secondary text-white': !isDueSoon,
                'text-danger bg-white fw-bold': isDueSoon,
              }"
            >
              Next Payment Due:<br />
              {{ dateNextPayment }}
            </div>
          </div>
          <div class="col-md-12">
            <router-link
              class="bg-warning text-dark"
              :to="{ name: 'Space-Sections', params: { slug: slug } }"
            >
              View Site Page
            </router-link>
          </div>

          <div class="col-md-12">
            <router-link class=" " :to="{ name: 'ShowBookings' }">
              Bookings
              <i class="fas fa-calendar-alt"></i>
            </router-link>
          </div>

          <div class="col-md-12">
            <router-link class=" " :to="{ name: 'RecurringBookings' }">
              Recurring Bookings
              <i class="fas fa-recycle"></i>
            </router-link>
          </div>

          <div class="col-md-12">
            <router-link class=" " :to="{ name: 'Products' }">
              Products
              <i class="fas fa-calendar"></i>
            </router-link>
          </div>

          <div class="col-md-12">
            <router-link class=" " :to="{ name: 'SpaceSettings' }">
              Settings
              <i class="fas fa-cog"></i>
            </router-link>
          </div>

          <!-- <div class="col-md-12"> -->
          <!-- <router-link class=" " :to="{name: 'Photos'}"> -->
          <!--   Photos -->
          <!--   <i class="fas fa-camera-retro"></i> -->
          <!-- </router-link> -->
          <!-- </div> -->

          <div class="col-md-12">
            <router-link class=" " :to="{ name: 'EquipmentList' }">
              Equipment List
              <i class="fas fa-ruler-vertical"></i>
            </router-link>
          </div>

          <div class="col-md-12">
            <router-link
              v-if="isAdmin"
              class="nav-item bg-primary text-light"
              :to="{ name: 'Admin' }"
            >
              User Admin
              <i class="fas fa-bolt"></i>
            </router-link>
          </div>
        </div>

        <div id="right-nav" class="col">
          <div class="row">
            <router-view :space="space" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#dateNextPayment {
  padding: 5px 10px;
  border-radius: 3px;
}
</style>
<style lang="scss">
#Space {
  .router-link-exact-active {
    background: blue;
    color: white;
  }
  padding-top: 10px;
  .space {
    background: white;
    border: 1px solid #ccc;
    border-radius: 1px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .right {
    background: white;
    border: 1px solid #ccc;
  }
  #left {
    padding-right: 10px;
  }
  .name {
    font-family: montserrat, sans-serif;
    background: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .nav-link {
    padding: 3px 8px;
    background: #fff;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(40, 167, 69);
    color: rgb(40, 167, 69);
    margin-right: 3px;
    margin-bottom: 3px;
  }
  .router-link-active {
    background-color: #495057;
    color: #fff;
  }
  #nav {
    margin-bottom: 10px;
  }
  #left-nav {
    @media (max-width: 768px) {
      display: flex;
    }
    flex-wrap: wrap;
    justify-content: flex-start;
    & > div {
      display: flex;
      flex-direction: column;
      width: auto;
      @media (min-width: 768px) {
        width: 100%;
      }
      a {
        padding: 5px 10px;
        border-radius: 3px;
        margin: 3px;
        background: rgb(180, 190, 195);
        color: white;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.55);
        &.router-link-active {
          background: rgb(230, 240, 245);
          color: #009d6b;
          font-weight: bold;
        }
        i {
          margin-right: 4px;
          color: inherit;
        }
      }
    }
  }
}
</style>
